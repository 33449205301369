// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6Ohdh4QpphN3yF242To2v4cb-7J3t60g",
  authDomain: "talesofreval-61b4e.firebaseapp.com",
  projectId: "talesofreval-61b4e",
  storageBucket: "talesofreval-61b4e.appspot.com",
  messagingSenderId: "430295287973",
  appId: "1:430295287973:web:91625fa1a80d5c7536e54c",
  measurementId: "G-7Z0K07BWM9"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);