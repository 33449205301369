import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFooterTexts, reset } from '../features/texts/textSlice';
import Spinner from '../components/Spinner';
import FooterColumnLeft from './footer-components/FooterColumnLeft.jsx';
import FooterColumnMiddle from './footer-components/FooterColumnMiddle.jsx';
import FooterColumnRight from './footer-components/FooterColumnRight.jsx';
import { toast } from 'react-toastify';

function Footer({setShowFreeBookNow}) {
  const dispatch = useDispatch();
  const { footer_texts, isLoading, isError, message } = useSelector(
    (state) => state.texts
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  useEffect(() => {
    dispatch(getFooterTexts());
  }, [dispatch]);

  if (isLoading || !footer_texts) {
    return <Spinner />;
  }

  return (
    <div className="footer section padding-80-top">
      <div className="container">
        <div className="footer-columns">
          <FooterColumnLeft texts={footer_texts} setShowFreeBookNow={setShowFreeBookNow}/>
          <FooterColumnMiddle texts={footer_texts} />
          <FooterColumnRight texts={footer_texts} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
