import React from 'react'


function AddText({addText}) {

    const createTag = (text) => {
        return text.split(' ').join('-').toLowerCase();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const category = e.target[0].value;
        const text = e.target[1].value;
        let translation = e.target[2].value;
        if(translation === '') {
            translation = text;
        }
        const tag = createTag(text);
        addText({category, text, text_estonian: translation, text_name: tag});
    }

    

  return (
    <div className='add-text'>
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Category" ></input>
            <textarea type="text" placeholder="Text" className='text'></textarea>
            <textarea type="text" placeholder="Translation" className='text'></textarea>
            <button type='submit'>Add Text</button>
        </form>
    </div>
  )
}

export default AddText
