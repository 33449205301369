import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import ButtonPrimary from '../style-components/ButtonPrimary';
import gps_game from '../../img/gps-game.png';
import { FaMapMarkerAlt } from "react-icons/fa";

const Marker = () => (
  <div style={{ fontSize: '34px', color: 'red', transform: 'translate(-50%, -100%)' }}>
    <FaMapMarkerAlt />
  </div>
);

function FooterColumnLeft({ texts, setShowFreeBookNow }) {
  const [midScreen, setMidScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMidScreen(window.innerWidth < 1400 && window.innerWidth > 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const defaultProps = {
    center: {
      lat: 59.436575996574305,
      lng: 24.74391712620674,
    },
    zoom: 17,
  };

  // Placeholder texts
  const joinFreeTourText = texts && texts["join-our-free-tour:"] ? texts["join-our-free-tour:"].text : '';
  const footerFirstTimeText = texts && texts["footer-first-time"] ? texts["footer-first-time"].text : '';
  const footerSecondTimeText = texts && texts["footer-second-time"] ? texts["footer-second-time"].text : '';
  const languageText = texts && texts["language-:-english"] ? texts["language-:-english"].text.split(':') : ["", ""];
  const durationText = texts && texts["duration:-90-minutes"] ? texts["duration:-90-minutes"].text.split(':') : ["", ""];
  const distanceText = texts && texts["distance:-1.2-km"] ? texts["distance:-1.2-km"].text.split(':') : ["", ""];
  const startingPointText = texts && texts["starting-point:-niguliste-2"] ? texts["starting-point:-niguliste-2"].text.split(':') : ["", ""];
  const openMapText = texts && texts["open-map"] ? texts["open-map"].text : '';
  const gpsGameText = texts && texts["our-gps-game"] ? texts["our-gps-game"].text : '';
  const funGameText = texts && texts["very-fun-game-and-cool-stuff"] ? texts["very-fun-game-and-cool-stuff"].text : '';
  const tryItOutText = texts && texts["try-it-out!"] ? texts["try-it-out!"].text : '';
  const bookFreeTourText = texts && texts["book-free-tour"] ? texts["book-free-tour"].text : '';

  const isSnap = navigator.userAgent === 'ReactSnap';

  return (
    <div className="footer-column side left">
      <h3 className="cardo">{joinFreeTourText}</h3>
      <h4 className="padding-20-top blue-text">{footerFirstTimeText}</h4>
      <h4 className="padding-10-top blue-text">{footerSecondTimeText}</h4>
      <ul className="padding-20-top free-tour-info">
        <li>{languageText[0]}:<span className="bold">{languageText[1]}</span></li>
        <li>{durationText[0]}:<span className="bold">{durationText[1]}</span></li>
        <li>{distanceText[0]}:<span className="bold">{distanceText[1]}</span></li>
        <li>{startingPointText[0]}:<span className="bold">{startingPointText[1]}</span></li>
      </ul>
      <div className="padding-10-top"></div>
      <ButtonPrimary text={bookFreeTourText} icon="ArrowRightUp" onClick={() => setShowFreeBookNow(true)} />
      {!isSnap ? (
        <div className="google-maps padding-20-top">
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAtOYxyBe86K7NBGQjitAYOjul-cy-c1LE" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{
              minZoom: 14, // Minimum zoom level
              maxZoom: 19, // Maximum zoom level
            }}
          >
            <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
          </GoogleMapReact>
        </div>
      ) : (
        <img src="path/to/static-map-image.jpg" alt="Static Map" className="google-maps padding-20-top" />
      )}
      <div className="button-container padding-20-top">
        
          
        <ButtonPrimary text={openMapText} icon="ArrowRightUp" link="https://maps.app.goo.gl/bVono2RWfCPvSp5x5" />
      </div>
      {midScreen ? (
        <div className="footer-column padding-80-top">
          <h3 className="cardo">{gpsGameText}</h3>
          <p className='padding-20-top'>{funGameText}</p>
          <img className="padding-10-top" src={gps_game} alt="" style={{ marginBottom: "-4px" }} />
          <div className="button-container">
            <ButtonPrimary text={tryItOutText} icon="ArrowRightUp" link="https://connect.leplace.online/storyline-talesofreval" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default FooterColumnLeft;



