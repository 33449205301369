import { useEffect, useState } from 'react';
import destination from '../img/destination.png';
import private_tour from '../img/private.png';
import pulmad from '../img/pulmad.png';
import quick from '../img/quick.png';
import team from '../img/team.png';
import ServiceCard from './style-components/ServiceCard';

function ServicesList({ texts }) {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1100 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const teamEventsText = texts && texts["team-events"] ? texts["team-events"].text : "";
  const privateTourText = texts && texts["private-tour"] ? texts["private-tour"].text : "";
  const quickTourText = texts && texts["\"we-only-have-30-minutes!\""] ? texts["\"we-only-have-30-minutes!\""].text : "";
  const destinationManagementText = texts && texts["destination-management"] ? texts["destination-management"].text : "";
  const fantasyWeddingsText = texts && texts["fantasy-weddings"] ? texts["fantasy-weddings"].text : "";

  return (
    <>
      {smallScreen ? (
        <>
          <div className="our-services-grid tripple">
            <ServiceCard
              link={"team"}
              bgimage={team}
              title={teamEventsText}
              text={teamEventsText}
            />
            <ServiceCard
              link={"private"}
              bgimage={private_tour}
              title={privateTourText}
              text={privateTourText}
            />
            <ServiceCard
              link={"quick"}
              bgimage={quick}
              title={quickTourText}
              text={quickTourText}
            />
          </div>

          <div className="our-services-grid double">
            <ServiceCard
              link={"destination"}
              bgimage={destination}
              title={destinationManagementText}
              text={destinationManagementText}
            />
            <ServiceCard
              link={"fantasy"}
              bgimage={pulmad}
              title={fantasyWeddingsText}
              text={fantasyWeddingsText}
            />
          </div>
        </>
      ) : (
        <>
          <div className="our-services-grid">
            <ServiceCard
              link={"team"}
              smallScreen={smallScreen}
              bgimage={team}
              title={teamEventsText}
              text={teamEventsText}
            />
            <ServiceCard
              link={"private"}
              smallScreen={smallScreen}
              bgimage={private_tour}
              title={privateTourText}
              text={privateTourText}
            />
            <ServiceCard
              link={"quick"}
              smallScreen={smallScreen}
              bgimage={quick}
              title={quickTourText}
              text={quickTourText}
            />
            <ServiceCard
              link={"destination"}
              smallScreen={smallScreen}
              bgimage={destination}
              title={destinationManagementText}
              text={destinationManagementText}
            />
            <ServiceCard
              link={"wedding"}
              smallScreen={smallScreen}
              bgimage={pulmad}
              title={fantasyWeddingsText}
              text={fantasyWeddingsText}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ServicesList;
