import axios from 'axios'

const API_URL = '/tours/'


// Get dates
const getDates = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

const initiateSripe = async () => {
    const data = {
        storyId: 401,
        connectUrl: "http://talesofreval.ee/"
    }    
    const response = await axios.post('https://leplace.leplace-api.com/business/payment/stripe/create-payment-checkout-session', data)
    return response.data
}

const addDate = async (token, data) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    const response = await axios.post(API_URL, data, config)
    return response.data
}

const removeDate = async (token, data) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    const response = await axios.post(API_URL + "remove", data, config)
    return response.data
}

const tourService = {
    getDates,
    addDate,
    removeDate,
    initiateSripe
}

export default tourService