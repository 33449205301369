import axios from 'axios'

const API_URL = '/texts/'


// Get texts
const getTexts = async () => {
    const response = await axios.get(API_URL + 'all')
    return response.data
}

// Get text by name
const getTextByName = async (name) => {
  const response = await axios.get(API_URL + name)
  return response.data
}

// Get text by category
const getTextByCategory = async (category) => {
  const language = localStorage.getItem("language");
  const response = await axios.get(API_URL + "category/" + category + "?language=" + language);
  const data = response.data;

  const textsByName = data.reduce((obj, text) => {
      obj[text.text_name] = text;
      return obj;
  }, {});

  return textsByName;
}

// Add text
const addText = async (token, text) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL, text, config)
  return response.data
}

// Edit text
const editText = async (token, text) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL, text, config)
  return response.data
}

const textService = {
    getTexts,
    addText,
    getTextByName,
    getTextByCategory,
    editText
}

export default textService