import {Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import Header from './components/Header';
import Home from './pages/Home';

import 'react-toastify/dist/ReactToastify.css'
import StyleGuide from './pages/StyleGuide';
import Footer from './components/Footer';
import ServicePage from './pages/ServicePage';
import StoryPage from './pages/StoryPage';
import AddEvent from './pages/AddEvent';
import ContactUs from './pages/ContactUs';
import BookNowPopup from './components/BookNowPopup';
import EditEvents from './pages/EditEvents';
import EditEvent from './pages/EditEvent';
import EditTexts from './pages/EditTexts';
import EditServices from './pages/EditServices';
import Login from './pages/Login';
import Logout from './pages/Logout';
import FreeBookNow from './components/FreeBookNow';
import VirtualTour from './pages/VirtualTour';

import AdminFreeTours from './pages/AdminFreeTours';

function App() {

  const [showBookNow, setShowBookNow] = useState(false);
  const [showFreeBookNow, setShowFreeBookNow] = useState(false);

  return (
    <>
      <Router>
        <Header setShowBookNow={setShowBookNow}/>  
          <BookNowPopup showBookNow={showBookNow} setShowBookNow={setShowBookNow}/>
          <FreeBookNow showBookNow={showFreeBookNow} setShowBookNow={setShowFreeBookNow}/>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/story' element={<StoryPage />} />
            <Route path='/contacts' element={<ContactUs />} />
            <Route path='/styles' element={<StyleGuide />} />
            <Route path="/service/:serviceType" element={<ServicePage setShowBookNow={setShowBookNow}/>} />
            <Route path='/' element={<Home />} />
            <Route path='/virtual' element={<VirtualTour />} />
            <Route path='/admin-add-event' element={<AddEvent />} />
            <Route path='/admin-edit-texts' element={<EditTexts />} />
            <Route path='/admin-edit-event/:id' element={<EditEvent />} />
            <Route path='/admin-edit-services' element={<EditServices />} />
            <Route path='/admin-edit-events' element={<EditEvents />} />
            {/* <Route path='/admin-bookings' element={<AdminBookings />} /> */}
            <Route path='/admin-free-tours' element={<AdminFreeTours />} />
            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        <Footer setShowFreeBookNow={setShowFreeBookNow}/>  
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;

