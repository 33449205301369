import axios from 'axios'

const API_URL = '/services/'


// Get services
const getServices = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

// Get service by name
const getService = async (name) => {
  const language = localStorage.getItem("language");
  const response = await axios.get(API_URL + name + "?language=" + language)
  return response.data
}

// Add service
const addService = async (token, service) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL, service, token)
  return response.data
}

// Edit service
const editService = async (token, service) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL, service, config)
  return response.data
}

const serviceService = {
    getServices,
    addService,
    getService,
    editService
}

export default serviceService