import HeaderBar from "./HeaderBar"
import logo from '../../img/logo.svg'
import { useNavigate } from "react-router-dom"
import AdminBar from "./AdminBar"

function AdminHeader({ourServicesOpen, setOurServicesOpen, setShowBookNow, texts, home_texts}) {

  const navigate = useNavigate()

  const navhome = () => {
    setOurServicesOpen(false)
    navigate("/")
  }

  return (
    <div className="header-container">
        <AdminBar texts={texts} home_texts={home_texts} setShowBookNow={setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/>
    </div>
  )
}

export default AdminHeader
