import axios from 'axios'

const API_URL = '/events/'


// Get events
const getEvents = async () => {
    const language = localStorage.getItem("language");
    const response = await axios.get(API_URL + "?language=" + language)
    return response.data
}

// Get events
const getEventData = async () => {
  const response = await axios.get(API_URL + 'data')
  return response.data
}

// Get events
const getEvent = async (id) => {
  const response = await axios.get(API_URL + id)
  return response.data
}

// Add event
const addEvent = async (token, event) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL, event, config)
  return response.data
}

// Edit event
const editYearEvent = async (token, id, eventObject) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + id, eventObject, config)
  return response.data
}

// Delete event
const deleteEvent = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.delete(API_URL + id, config)
  return response.data
}

const eventService = {
  getEvents,
  addEvent,
  getEvent,
  editYearEvent,
  getEventData,
  deleteEvent
}

export default eventService