import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventData, deleteEvent, reset } from '../features/events/eventSlice';
import Spinner from '../components/Spinner';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function EditEvents() {

  const dispatch = useDispatch();
    const navigate = useNavigate();

  const { events, isLoading, isError, message } = useSelector(
      (state) => state.events
  )

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
      if (isError) {
          toast.error(message);
      }

      return () => {
          dispatch(reset());
      };
  }, [events, isError, message, dispatch]);

  useEffect(() => {
      dispatch(getEventData());
  }, []);

  const handleDelete = (id) => {
        dispatch(deleteEvent(id));
    }

  if(isLoading || !events){
      return <Spinner />
  }


  return (
    <div>
      <div className='edit-events'>
        <div className="edit-events-title" style={{width: "49.5%"}}>
          <h3 className='admin-heading padding-20-bottom'>Edit Events</h3>
          <button className="btn" onClick={() => navigate("/admin-add-event")}>New Event</button>
        </div>
      
      <table>
        <thead>
          <tr>
            <th className="title">Title</th>
            <th className="year">Year</th>
            <th className = "content">Content Type</th>
            <th className="action">Edit</th>
            <th className="action">Remove</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={index}>
                <td className="title">{event.title}</td>
                <td className="year">{event.year}</td>
                <td className = "content">{event.mediaType === 0 ? "Image" : event.mediaType === 1 ? "Multi-Image" : "Video"}</td>
                <td className="action">
                    <button><FaRegEdit size={16} onClick={() => navigate(`/admin-edit-event/${event._id}`)}/></button>
                </td>
                <td className="action">
                    <button onClick={() => handleDelete(event._id)}><FaRegTrashAlt size={16}/></button>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default EditEvents
