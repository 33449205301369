import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServices, editService, reset } from '../features/services/serviceSlice';
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { v4 } from 'uuid';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import { app } from '../firebase';
import imageCompression from 'browser-image-compression';

function EditServices() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const storage = getStorage(app);

    const { services, isLoading, isError, message } = useSelector((state) => state.services);

    const singleFileInputRef = useRef([]);

    const [editting, setEditting] = useState(false);
    const [title, setTitle] = useState("");
    const [titleEstonian, setTitleEstonian] = useState("");
    const [intro, setIntro] = useState("");
    const [introEstonian, setIntroEstonian] = useState("");
    const [review, setReview] = useState("");
    const [reviewEstonian, setReviewEstonian] = useState("");
    const [reviewAuthor, setReviewAuthor] = useState("");
    const [reviewAuthorEstonian, setReviewAuthorEstonian] = useState("");
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [texts, setTexts] = useState([]);
    const [service, setService] = useState(null);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
        return () => {
            dispatch(reset());
        };
    }, [services, isError, message, dispatch]);

    useEffect(() => {
        dispatch(getServices());
    }, [dispatch]);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!user) {
        navigate('/');
        }
    }, [user, navigate]);

    const handleServiceClick = (id) => {
        const serviceObject = services.find((service) => service._id === id);
        setService(serviceObject);
        setTitle(serviceObject.title);
        setTitleEstonian(serviceObject.title_estonian);
        setIntro(serviceObject.intro);
        setIntroEstonian(serviceObject.intro_estonian);
        setReview(serviceObject.review);
        setReviewEstonian(serviceObject.review_estonian);
        setReviewAuthor(serviceObject.review_author);
        setReviewAuthorEstonian(serviceObject.review_author_estonian);
        setBackgroundImage(serviceObject.background_image);
        setTexts(serviceObject.texts);
        setEditting(true);
    };

    const handleSingleFileChange = async (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const name = await uploadImage(file, file.name);
            const url = URL.createObjectURL(file);
            const newTexts = texts.map((text, i) => {
                if (i === index) {
                    return { ...text, text_image: { src: url, name: name } };
                }
                return text;
            });
            setTexts(newTexts);
        }
    };

    const handleTextChange = (index, field, event) => {
        const newTexts = texts.map((text, i) => {
            if (i === index) {
                return { ...text, [field]: event.target.value };
            }
            return text;
        });
        setTexts(newTexts);
    };

    const uploadImage = async (file, originalName) => {
        try {
            if (file == null) return;
    
            // Generate a unique identifier for the image name
            const imageName = v4();
    
            // Define the maximum width/height
            const maxWidthOrHeight = 800;
    
            // Resize the image
            const compressedFile = await imageCompression(file, {
                maxWidthOrHeight,
                useWebWorker: true,
            });
    
            // Upload the resized image
            const imageRef = ref(storage, `services/${imageName}`);
            const uploadTask = uploadBytesResumable(imageRef, compressedFile);
    
            return new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    },
                    (error) => {
                        toast.error("Something went wrong with image upload");
                        reject(error);
                    },
                    () => {
                        toast.success("Image uploaded successfully");
                        // Resolve with the generated image name
                        resolve(imageName);
                    }
                );
            });
        } catch (error) {
            toast.error("Something went wrong with image compression");
            throw error;
        }
    };



    const click = async () => {
        const newTexts = texts.map(text => ({
            ...text,
            text_image: text.text_image.name
        }));

        dispatch(editService({
            _id: service._id,
            title,
            title_estonian: titleEstonian,
            intro,
            intro_estonian: introEstonian,
            review,
            review_estonian: reviewEstonian,
            review_author: reviewAuthor,
            review_author_estonian: reviewAuthorEstonian,
            texts: newTexts
        }));

        handleStopEdit();
    };

    const handleStopEdit = () => {
        setEditting(false);
        setService(null);
        setTitle("");
        setIntro("");
        setReview("");
        setReviewAuthor("");
        setReviewAuthorEstonian("");
        setBackgroundImage(null);
        setTexts([]);
    };

    if (isLoading || (!service && editting)) {
        return <Spinner />;
    }

    return (
        <div className='edit-events'>
            <div className="edit-events-title" style={{width: "49.5%"}}>
                <h3 className='admin-heading padding-20-bottom'>
                    {!editting ? "Edit Services" : `Edit Service: `}
                    <span style={{fontSize: "1.5rem"}}>{editting ? service.title : ""}</span>
                </h3>
            </div>
            {!editting ?
                <table className='edit-text-table'>
                    <thead>
                        <tr>
                            <th className="category">Service (click to edit...)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {services && services.length > 0 ? (
                            services.map((service, index) => (
                                <tr key={index} style={{cursor: "pointer"}} onClick={() => handleServiceClick(service._id)}>
                                    <td className="title">{service.title}</td>
                                </tr>
                            ))
                        ) : null}
                    </tbody>
                </table>
            :
                <div className='edit-service'>
                    <div className="edit-service-buttons">
                        <button className="btn" onClick={handleStopEdit}>Back</button>
                        <button className="btn" onClick={click}>Save</button>
                    </div>

                    <form>
                        <input value={title || ""} type="text" placeholder='Title' onChange={(e) => setTitle(e.target.value)} />
                        <input value={titleEstonian || ""} type="text" placeholder='Title Estonian' onChange={(e) => setTitleEstonian(e.target.value)} />
                        <textarea value={intro || ""} className="service-intro-input" type="text" placeholder='Intro' onChange={(e) => setIntro(e.target.value)} />
                        <textarea value={introEstonian || ""} className="service-intro-input" type="text" placeholder='Intro Estonian' onChange={(e) => setIntroEstonian(e.target.value)} />
                        <textarea value={review || ""} className="service-intro-input" type="text" placeholder='Review' onChange={(e) => setReview(e.target.value)} />
                        <textarea value={reviewEstonian || ""} className="service-intro-input" type="text" placeholder='Review Estonian' onChange={(e) => setReviewEstonian(e.target.value)} />
                        <input value={reviewAuthor || ""} type="text" placeholder='Review Author' onChange={(e) => setReviewAuthor(e.target.value)} />
                        <input value={reviewAuthorEstonian || ""} type="text" placeholder='Review Author Estonian' onChange={(e) => setReviewAuthorEstonian(e.target.value)} />
                        <h5 className='padding-40-top padding-10-bottom'>Texts:</h5>
                        <div className="service-texts">
                            {texts.map((text, index) => (
                                <div className="text-input" key={index}>
                                    <input value={text.text_title} type="text" placeholder='Text Title' onChange={(event) => handleTextChange(index, 'text_title', event)} />
                                    <input value={text.text_title_estonian} type="text" placeholder='Text Title Estonian' onChange={(event) => handleTextChange(index, 'text_title_estonian', event)} />
                                    <textarea className="service-intro-input" value={text.text_english} type="text" placeholder='Text English' onChange={(event) => handleTextChange(index, 'text_english', event)} />
                                    <textarea className="service-intro-input" value={text.text_estonian} type="text" placeholder='Text Estonian' onChange={(event) => handleTextChange(index, 'text_estonian', event)} />
                                    <input type="file" ref={el => singleFileInputRef.current[index] = el} style={{ display: 'none' }} onChange={(event) => handleSingleFileChange(index, event)} />
                                    <div className="text-img-box">
                                        <img src={text.text_image.src} alt="" />
                                        <FaTimes size={20} className='remove-image' onClick={() => singleFileInputRef.current[index].click()} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}

export default EditServices;