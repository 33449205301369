import homebg from '../img/home-bg.png';

function HomeLanding({ texts }) {
  if (!texts) {
    return null;
  }

  const storytellingText = texts["storytelling"] ? texts["storytelling"].text : null;
  const reinventedText = texts["reinvented"] ? texts["reinvented"].text : null;
  const imaginationVoiceText = texts["imagination-voice"] ? texts["imagination-voice"].text : null;

  return (
    <div className="home-landing" style={{ background: `url(${homebg})` }}>
      <div className="container home-landing-content">
        <h1>
          {storytellingText} <br />
          {reinventedText}
        </h1>
        <p className="xl home-landing-text">
          {imaginationVoiceText}
        </p>
      </div>
    </div>
  );
}

export default HomeLanding;
