import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serviceService from './serviceService'

const initialState = {
  services: [],
  service: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get services
export const getServices = createAsyncThunk('services/all', async (thunkAPI) => {
  try {
    return await serviceService.getServices()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get service
export const getService = createAsyncThunk('services/:name', async (name, thunkAPI) => {
  try {
    return await serviceService.getService(name)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Add service
export const addService = createAsyncThunk('services/add', async (service, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serviceService.addService(token, service)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Edit service
export const editService = createAsyncThunk('services/edit', async (service, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serviceService.editService(token, service)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const serviceSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = action.payload
      })
      .addCase(getServices.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.services = []
      })
      .addCase(addService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = [...state.services, action.payload]
      })
      .addCase(addService.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.service = action.payload
      })
      .addCase(getService.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = state.services.map((service) =>
          service._id === action.payload._id ? action.payload : service
        )
      })
      .addCase(editService.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      
  },
})

export const { reset } = serviceSlice.actions
export default serviceSlice.reducer