import { Link } from "react-router-dom"

function ServiceCard({bgimage, title, mobile, link, setOurServicesOpen}) {

  return (
    <Link to={`/service/${link}`} onClick={() => setOurServicesOpen(false)}>
      <div className={`service ${mobile ? 'mobile-service' : ''}`} style={{backgroundImage: `url(${bgimage})`}}>
          <div className="service-info">
              <h5 className="blue-text">{title}</h5>
          </div>
      </div>
    </Link>
  )
}

export default ServiceCard