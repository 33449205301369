import React, { useState, useEffect } from 'react';
import { TripAdvisor } from "../../icons/TripAdvisor.tsx";
import { AirBnB } from "../../icons/AirBnB.tsx";
import { Facebook } from "../../icons/Facebook.tsx";
import { Instagram } from "../../icons/Instagram.tsx";
import { ArrowRightUp } from "../../icons/ArrowRightUp.tsx";
import { Link } from 'react-router-dom';

function SocialButton({text, icon, link}) {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <button className="button-social">
        <div className="icon-and-text">
          {icon === 'TripAdvisor' && <span className="icon-span-left"><TripAdvisor size={mobile ? "2rem" : "1.5rem"}/></span>}
          {icon === 'AirBnB' && <span className="icon-span-left"> <AirBnB size={mobile ? "2rem" : "1.5rem"}/></span>}
          {icon === 'Facebook' && <span className="icon-span-left"> <Facebook size={mobile ? "2rem" : "1.5rem"}/></span>}
          {icon === 'Instagram' && <span className="icon-span-left"> <Instagram size={mobile ? "2rem" : "1.5rem"}/></span>}
          {!mobile && <span>{text}</span>}
        </div>
        {!mobile && 
          <div className="icon-arrow">
            <span className="icon-span-right-far">
                <ArrowRightUp size="1.2rem"/>
            </span>
          </div>
        }
      </button>
    </a>
  )
}

export default SocialButton