import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })

  const { username, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
  
    if (user) {
      navigate('/')
    }
  
    // Return a cleanup function that runs when the component unmounts
    return () => {
      dispatch(reset())
    }
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      username,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="login-page">
        <div className='login'>
            <div className="login-card">
                <section className="login-title">
                    <h1>ToR Login</h1>
                    <p>Please log in to view admin side of TalesOfReval</p>
                </section>
        
            <section className='login-form'>
                <form onSubmit={onSubmit}>
                <div className='form-group username'>
                    <input
                    type='text'
                    className='form-control'
                    id='username'
                    name='username'
                    value={username}
                    placeholder='Username'
                    onChange={onChange}
                    />
                </div>
                <div className='form-group password'>
                    <input
                    type='password'
                    className='form-control'
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Password'
                    onChange={onChange}
                    />
                </div>

                <div className='form-group login'>
                    <button type='submit' className='login btn btn-block'>
                    Login
                    </button>
                </div>
                </form>
            </section>
            </div>
            </div>
    </div>
    
  )
}

export default Login