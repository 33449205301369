import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import eventService from './eventService'
import { toast } from 'react-toastify';

const initialState = {
  events: [],
  event: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// get events
export const getEvents = createAsyncThunk('events/all', async (thunkAPI) => {
  try {
    return await eventService.getEvents()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// get events
export const getEventData = createAsyncThunk('events/all/data', async (thunkAPI) => {
  try {
    return await eventService.getEventData()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// add event
export const addEvent = createAsyncThunk('events/add', async (event, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await eventService.addEvent(token, event)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// edit event
export const editYearEvent = createAsyncThunk('events/edit', async ({id, event}, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await eventService.editYearEvent(token, id, event)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// add event
export const getEvent = createAsyncThunk('events/:id', async (id, thunkAPI) => {
  try {
    return await eventService.getEvent(id)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// delete event
export const deleteEvent = createAsyncThunk('events/delete', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await eventService.deleteEvent(token, id)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})


export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.events = action.payload
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.events = null
      })
      .addCase(addEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.events = [...state.events, action.payload]
        toast.success('Event added successfully');
      })
      .addCase(addEvent.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.events = null
      })
      .addCase(getEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.events = [...state.events, action.payload]
        state.event = action.payload
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.events = null
      })
      .addCase(editYearEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editYearEvent.fulfilled, (state, action) => {
          state.events = state.events.map(event => 
              event._id === action.payload._id ? action.payload : event
          );
          state.isSuccess = true;
          state.isLoading = false;
          toast.success('Event updated successfully');
      })
      .addCase(editYearEvent.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
          state.events = null;
      })
      .addCase(getEventData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEventData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.events = action.payload
      })
      .addCase(getEventData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.events = null
      })
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.events = state.events.filter((event) => event._id !== action.payload._id)
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.events = null
      })
  },
})

export const { reset } = eventSlice.actions
export default eventSlice.reducer