import { useEffect, useRef, useState } from 'react';
import { getStorage, ref, uploadBytesResumable} from 'firebase/storage';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { app } from '../firebase';
import { FaVideo, FaImages, FaImage, FaTimes } from "react-icons/fa";
import { addEvent } from '../features/events/eventSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import imageCompression from 'browser-image-compression';


function AddEvent() {
  const [year, setYear] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [gallery, setGallery] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [mediaInputType, setMediaInputType] = useState(null);
  const [title2, setTitle2] = useState("");
  const [description2, setDescription2] = useState("");
  const [order, setOrder] = useState(0);

  

  const [loading, setLoading] = useState(false);

  const storage = getStorage(app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);


  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  
  
  // Add a reference to the file inputs
  const singleFileInputRef = useRef(null);
  const multipleFileInputRef = useRef(null);

  const submitForm =  async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the promise starts
  
    const names = await uploadGallery();
  
    const videoData = mediaInputType == 2 ? video : null;
    const galleryData = mediaInputType == 1 ? names : null;
    const imageData = mediaInputType == 0 ? names[0] : null;
  
    // Ensure that at least one type of media is included
    if (!videoData && !galleryData && !imageData) {
      toast.error("Problem with media");
      return;
    }
  
    const event = {
      year: year,
      title: title,
      description: description,
      image: imageData,
      images: galleryData,
      video: videoData,
      mediaType: mediaInputType,
      title2: title2,
      description2: description2,
      order: order,
    };
    dispatch(addEvent(event));
    navigate('/story');
  };

  const uploadImage = async (file, name) => {
    try {
      if (file == null) return;
  
      // Define the maximum width/height
      const maxWidthOrHeight = 1000;
  
      // Resize the image
      const compressedFile = await imageCompression(file, {
        maxWidthOrHeight,
        useWebWorker: true,
        outputFormat: 'webp'
      });
  
      // Upload the resized image
      const imageRef = ref(storage, `timeline/${name}`);
      const uploadTask = uploadBytesResumable(imageRef, compressedFile);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            toast.error("Something went wrong with image upload");
            reject(error);
          },
          () => {
            toast.success("Image uploaded successfully");
            resolve(name);
          }
        );
      });
    } catch (error) {
      toast.error("Something went wrong with image compression");
      throw error;
    }
  };

  const uploadGallery = async () => {
    if (imageFiles.length === 1) {
      const name = imageFiles[0].name + v4();
      await uploadImage(imageFiles[0], name);
      return [name];
    } else {
      const uploadPromises = imageFiles.map(file => {
        const name = file.name + v4();
        return uploadImage(file, name);
      });
      const names = await Promise.all(uploadPromises);
      return names;
    }
  };

  const handleSingleFileChange = (e) => {
    setImageFiles(Array.from(e.target.files));
    const file = e.target.files[0];
    
    if(file){
      const url = URL.createObjectURL(file);
      setGallery([{ src: url, name: file.name }]);
    }
  }
  
  const handleMultipleFileChange = (e) => {
    setImageFiles(Array.from(e.target.files));
    // Convert the FileList to an array and upload each file
    Array.from(e.target.files).forEach((file) => {
      const url = URL.createObjectURL(file);
      setGallery((prev) => [...prev, { src: url, name: file.name }]);
    });
  }


  // Trigger the file input when the media input div is clicked
  const handlePhotoInput = () => {
    setMediaInputType(0);
    setGallery([]);
    setVideo("");
    singleFileInputRef.current.click();
  }

  const handleGalleryInput = () => {
    setMediaInputType(1);
    setGallery([]);
    setVideo("");
    multipleFileInputRef.current.click();
  }

  const handleVideoInput = () => {
    setMediaInputType(2);
    setGallery([]);
  }

  const removeFromGallery = (index) => {
    if(gallery.length === 1){
      setGallery([]);
      setImageFiles([]);
      return;
    }
    setImageFiles(imageFiles.filter((file, i) => i !== index));
    setGallery(gallery.filter((img, i) => i !== index));
  }

  if(loading) return <Spinner />

  return (
    <div className="container add-event">
      <h2 className='admin-heading padding-20-bottom'>Add Event</h2>
      <form className="add-event-form" onSubmit={submitForm}>
        <input type="number" value={year} onChange={(e) => setYear(e.target.value)} required placeholder="Year" />
        <input type="number" value={order} onChange={(e) => setOrder(e.target.value)} required placeholder="Order in year" />
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required placeholder="English Title" />
        <input type="text" value={title2} onChange={(e) => setTitle2(e.target.value)} required placeholder="Estonian Title" />
        
        <div className="textarea-input">
          <ReactQuill placeholder="English description" className="my-quill" value={description} onChange={setDescription} />
        </div>
        <div className="textarea-input">
          <ReactQuill placeholder="Estonian description" className="my-quill" value={description2} onChange={setDescription2} />
        </div>
        {mediaInputType == 2 && <input type="text" value={video} onChange={(e) => setVideo(e.target.value)} placeholder="Video" />}
        
        <div className="media-inputs">
          <div className={`media-input-card ${mediaInputType == 0 ? "clicked" : ""}`} onClick={handlePhotoInput}>
            <FaImage />
              <input type="file" ref={singleFileInputRef} style={{ display: 'none' }} onChange={handleSingleFileChange} />
            {/* hidden input single photo, auto upload and set gallery to this single image */}
          </div>
          <div className={`media-input-card ${mediaInputType == 1 ? "clicked" : ""}`} onClick={handleGalleryInput}>
            <FaImages />
              <input type="file" ref={multipleFileInputRef} multiple style={{ display: 'none' }} onChange={handleMultipleFileChange} />
            {/* hidden input multiple images, auto upload and set gallery */}
          </div>
          <div className={`media-input-card ${mediaInputType == 2 ? "clicked" : ""}`} onClick={handleVideoInput}>
            <FaVideo />
          </div>
        </div>
        {gallery.length > 0 && 
          <div className="mini-gallery">
            {gallery.map((image, index) => (
              <div className="mini-gallery-image-container">
                <FaTimes className="mini-gallery-image-remove" color='red' onClick={() => removeFromGallery(index)}/>
                <img key={index} src={image.src} alt={image.name} />
              </div>
            ))}
          </div>
        }
        
      
        <button type="submit" className="button-primary add-event-submit">Add Event</button>
      </form>
    </div>
  );
}

export default AddEvent;